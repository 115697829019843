import { ENDPOINTS } from "../endpoints";
import { Repository } from "../repository";
import { CollectionArticle, ResponseCollection, ResponseData } from "../types";

export class ArticlesApi extends Repository {
    getArticlesData = async (params?: object) => {
        const result: ResponseCollection<ResponseData<CollectionArticle>> = await this.client.request.get(
            ENDPOINTS.articles,
            {
                sort: ["sort:asc", "id:desc"],
                fields: ["slug", "sort", "title", "readingTime", "publishedAt"],
                populate: "imagePreview,category",
                ...params,
            }
        );

        return result?.data ?? [];
    };
}
