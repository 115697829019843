import { FC } from "react";
import { isbot } from "isbot";

import { YandexMetrika } from "./YandexMetrika";

export type AnalyticsProps = {
    userAgent?: string;
};

export const Analytics: FC<AnalyticsProps> = ({ userAgent }) => {
    if (isbot(userAgent)) {
        return;
    }

    return (
        <>
            <YandexMetrika />
        </>
    );
};
